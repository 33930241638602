import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

export const MetaHelper = (props) => {
  const { asPath } = useRouter();

  const canonicalUrl = `https://uuusoftware.com/${asPath}`;
  const title =
    props?.seo?.title || 'UUUSoftware is a Canadian custom software development company';
  const description =
    props?.seo?.description ||
    'We support our clients by building digital solutions and porting them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.';
  const coverImg = props?.seo?.cover || 'https://uuusoftware.com/assets/images/preview.png';
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'UUUSoftware.com',
    description:
      'We help clients build tailored digital solutions and port them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.',
    logo: 'https://uuusoftware.com/assets/images/uuusoftware-logo.png',
    url: 'https://uuusoftware.com/',
    telephone: '+1 647 894 6457',
    sameAs: [
      'https://x.com/uuusoftware',
      'https://www.linkedin.com/company/uuusoftware-com',
      'https://www.facebook.com/uuusoftware'
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Unit 638, 121 Richmond St W',
      addressLocality: 'Toronto',
      postalCode: 'M5H 2K1',
      addressCountry: 'Canada'
    }
  };

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href={require('@/assets/favicon.ico').default.src} />
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={coverImg} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="UUUSoftware" />
        <meta name="twitter:site" content="@uuusoftware" />
        <meta name="twitter:creator" content="@uuusoftware" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={coverImg} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {props?.excludeFromSitemap && <meta name="robots" content="noindex, nofollow" />}
      </Head>
      <Script type="application/ld+json" id="seo-data">
        {JSON.stringify(structuredData)}
      </Script>
    </>
  );
};
